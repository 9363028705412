import rebarImg from './rebar.jpg';
import fibraImg from './fibra.jpg';

export const productImages = [
  {
    id: 1,
    imgSrc: rebarImg,
  },
  {
    id: 2,
    imgSrc: fibraImg,
  },
];
