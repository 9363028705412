import phoneIcon from './phone.svg';
import emailIcon from './email.svg';
import headIcon from './head.svg';

export const icons = [
  {
    contacId: '1',
    src: phoneIcon,
  },
  {
    contacId: '2',
    src: emailIcon,
  },
  {
    contacId: '3',
    src: headIcon,
  },
];
